import React from "react";
import { Link } from "gatsby";
import css from "./NavTile.module.scss";

const NavTile = ({ href, text, bgColor, textColor }) => (
  <Link
    className={css.navTile}
    to={href}
    style={{ backgroundColor: bgColor, color: textColor }}
    title={text}
  >
    <h2 className={css.text}>{text}</h2>
  </Link>
);

export default NavTile;
