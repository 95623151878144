import React from "react";

import { Link } from "gatsby";
import Layout from "../components/homeLayout";
import NavTile from "../components/NavTile";
import { bronze, dusk, gray, green, sky } from "mineral-ui-tokens";
import HiItsMeHeader from "../components/hiItsMeHeader";

import css from "./index.module.scss";

const IndexPage = () => (
  <Layout>
    <HiItsMeHeader />
    <div>
      <Link to="/account">Go to your account</Link>
    </div>

    <nav className={css.navStack}>
      <NavTile
        href="/professional/"
        text="Professional"
        bgColor={bronze[30]}
        textColor={gray[90]}
      />
      <NavTile
        href="/reading/"
        text="Reading"
        bgColor={dusk[60]}
        textColor={gray[10]}
      />
      <NavTile
        href="/ignorance/"
        text="Ignorance"
        bgColor={green[30]}
        textColor={gray[90]}
      />
      <NavTile
        href="/colophon/"
        text="Colophon"
        bgColor={sky[60]}
        textColor={gray[10]}
      />
    </nav>
  </Layout>
);

export default IndexPage;
