import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

// import "./global.scss";
import css from "./homeLayout.module.scss";

const HomeLayout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HomeTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: "author", content: "Trevor Farlow" },
            {
              name: "description",
              content:
                "Personal and professional web dwelling of Trevor Farlow.",
            },
            {
              name: "keywords",
              content: "Trevor Farlow, human software craftsmanship",
            },
            { name: "language", content: "English" },
            { name: "viewport", content: "width=device-width" },
          ]}
        >
          <html lang="en" />
          <link
            href="https://fonts.googleapis.com/css?family=La+Belle+Aurore|Raleway"
            rel="stylesheet"
          />
        </Helmet>
        <div className={css.content}>{children}</div>
      </>
    )}
  />
);

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HomeLayout;
